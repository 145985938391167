const navList = document.querySelector("#nav-menu"),
  navBtn = document.querySelector("#menuToggle"),
  navBtnImg = document.querySelector("#nav-btn-img"),
  navMenuItem = document.querySelectorAll(".nav-menu-item"),
  buttonClose = document.querySelector("#button-close");

navBtn.addEventListener("click", () => {
  // navBtnImg.src = "/src/img/profile/avatar.png";
  navList.classList.add("open");
});

navMenuItem.forEach((item) => {
  item.addEventListener("click", () => {
    navList.classList.remove("open");
  });
});

buttonClose.addEventListener("click", () => {
  navList.classList.remove("open");
});
