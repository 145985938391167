const slides = document.querySelectorAll(".card"),
  prev = document.querySelector(".swiper-button-prev"),
  next = document.querySelector(".swiper-button-next");
let slideIndex = 1;

showSlides(slideIndex);

function showSlides(n) {
  if (n > slides.length) {
    slideIndex = 1;
  }
  if (n < 1) {
    slideIndex = slides.length;
  }

  slides.forEach((item) => {
    item.style.display = "none";
  });

  slides[slideIndex - 1].style.display = "block";
}

function plusSlides(n) {
  showSlides((slideIndex += n));
}

prev.addEventListener("click", () => {
  plusSlides(-1);
  console.log("click prev");
});
next.addEventListener("click", () => {
  plusSlides(1);
  console.log("click next");
});
// ************************************************************************
